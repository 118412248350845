<template>
  <bg-page>
    <div class="Box">
      <div class="BackgroundBox">
        <div class="PersonInfo limitBox">
          <div v-if="state.personInfo.img">
            <el-image
              :src="state.personInfo?.img"
              fit="cover"
              style="width: 150px; height: 150px"
            >
              <template #error>
                <div class="image-slot">
                  <span>加载中</span>
                </div>
              </template>
            </el-image>
          </div>
          <div>
            <p>{{ state.personInfo.title }}</p>
            <p>
              <span
                v-if="state.personInfo.cate"
                >产业文化方向：{{
                  state.personInfo.cate.name
                }}</span>
              <span v-if="state.personInfo.city">所在地：{{ state.personInfo.city.name }}</span>
            </p>
            <p>{{ state.personInfo.info }}</p>
          </div>
        </div>
        <div class="BackgroundBox2" v-if="state.worksList[0].length">
          <div class="WorkInfo limitBox">
            <div>
              <p><span class="SecondTitle">作品展示</span></p>
              <p>
                <span class="iconfont iconhoutui" @click="left"></span>
                <span> {{ ("0" + (state.curWorkIndex + 1)).substr(-2) }} </span>
                <span>/ {{ ("0" + state.worksList.length).substr(-2) }} </span>
                <span class="iconfont iconqianjin" @click="right"></span>
              </p>
            </div>
            <div>
              <div
                v-for="(item, index) in state.worksList[0]"
                :key="index"
                class="WorkItem"
              >
                <div>
                  <el-image
                    :src="item.img"
                    :preview-src-list="[item.img]"
                    fit="cover"
                    style="width: 100%; height: 200px"
                  >
                    <template #error>
                      <div class="image-slot">
                        <span>加载中</span>
                      </div>
                    </template>
                  </el-image>
                  <p class="WorkName">{{ item.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="PersonIntro limitBox">
          <div>
            <p><span class="SecondTitle">人物介绍</span></p>
            <p v-html="state.personInfo.html.html"></p>
          </div>
        </div>
      </div>
    </div>
  </bg-page>
</template>

<script>
import * as Vue from "vue";
import router from "@/router";
import api from "@/comment/request/api";

export default {
  name: "CharacterDetails",
  components: { },
  setup(props, { attrs, slots, emit }) {
    const state = Vue.reactive({
      personInfo: {html: {}},
      worksList: [[]],
      curWorkIndex: 0,
    });
    let id = router.currentRoute._rawValue.query.id;
    api.GetCharacterDetails(id).success((res) => {
      state.personInfo = res.data.data;
      state.worksList = res.data.data.worksList ? [res.data.data.worksList] : [[]];
    });
    // 左
    const left = () => {
      if (state.curWorkIndex > 0) {
        state.curWorkIndex = --state.curWorkIndex;
      }
    };
    // 右
    const right = () => {
      if (state.curWorkIndex < state.worksList.length - 1) {
        state.curWorkIndex = ++state.curWorkIndex;
      }
    };
    return {
      state,
      left,
      right,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "/src/comment/comment";

.Box {
  padding-bottom: 0;

  .PersonInfo {
    text-align: center;
    padding-bottom: 30px;

    > div:nth-child(1) {
      padding-top: 40px;

      /deep/ .el-image {
        border-radius: 50%;
      }
    }

    > div:nth-child(2) {
      p {
        margin-top: 10px;
      }

      p:nth-child(1) {
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }

      p:nth-child(2) {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;

        span:nth-child(1) {
          margin-right: 30px;
        }
      }

      p:nth-child(3) {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #7f0404;
      }
    }
  }

  .BackgroundBox2 {
    background-color: #ececec;

    .WorkInfo {
      > div:nth-child(1) {
        width: 100%;
        //height: 36px;
        line-height: 36px;
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        justify-content: space-between;

        p:nth-child(2) {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #7f0404;
          .iconfont {
            cursor: pointer;
          }

          span:nth-child(3) {
            color: #333333;
          }
        }
      }

      > div:nth-child(2) {
        display: flex;
        justify-content: flex-start;
        padding-bottom: 30px;

        .WorkItem {
          width: calc(100% / 4);
          > div {
            width: calc(100% - 20px);

            .WorkName {
              font-size: 18px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
              margin-top: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .PersonIntro {
    margin-top: 40px;
    padding-bottom: 40px;

    > div:nth-child(1) {
      background-color: white;
      padding-top: 30px;

      > p:nth-child(2) {
        padding: 30px;
        text-indent: 2em;
      }
      p {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
      }
    }
  }
}
</style>